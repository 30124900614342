import './App.css';
import React from 'react';
import Navigation from './components/Navigation';
import {Card, Col, Container, Row} from "react-bootstrap";
import m1120 from "./static/m1120.jpg";
import icon from "./static/icon.png";
import m1075 from "./static/m1075.jpg";
import m1078 from "./static/m1078a1.jpg";
import m1083 from "./static/m1083.jpg";
import m1085 from "./static/m1085.jpg";
import m1087 from "./static/m1087.jpg";
import m1089 from "./static/m1089.jpg";
import m978 from "./static/m978.jpg";
import m1101 from "./static/m1101.jpg";
import m1076 from "./static/m1076.jpg";

const Home = () => {
    const Cards = [{

        "title": "M1120 A4",
        "subtitle": "HEMTT Load Handling System(HLS)",
        "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140573&authkey=!AFJB-fN-fj1MClY&em=2',
        "img": m1120
    }, {

        "title": "TRK CGO PLS M1075",
        "subtitle": "PALLETIZED LOAD SYSTEM (PLS)",
        "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140571&authkey=!AI148iLZy19MRos&em=2',
        "img": m1075
    },


        {

            "title": "M1078A1",
            "subtitle": "Light Medium Tactical Vehicle (LMTV)",
            "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140574&authkey=!AHY18abDKC5noEU&em=2',
            "img": m1078
        }, {

            "title": "M1083A1P2WOW",
            "subtitle": "5 ton Standard Cargo",
            "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140577&authkey=!ANKQQKicfeEcK_w&em=2',
            "img": m1083
        }, {

            "title": "M1085A1P2WOW", "subtitle": "5 ton Long Cargo", "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140577&authkey=!ANKQQKicfeEcK_w&em=2', "img": m1085
        },
        {

            "title": "M1087A1P2WOW", "subtitle": "FMTV Expansible Van", "url": '#', "img": m1087
        }, {

            "title": "M1089A1P2", "subtitle": "HEMTT Load Handling System", "url": '', "img": m1089
        },
        {

            "title": "M978A4WOW FUEL TANKER", "subtitle": "-", "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140581&authkey=!ANQYZhfwBYCHVLc&em=2', "img": m978

        }, {

            "title": "M1101 HMVW Trailer",
            "subtitle": "TRAILER,CARGO HIGH MOBILITY, 3/4T- M1101 & 1 1/4T - M1102",
            "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140579&authkey=!AA0h0tr0pzrgDVY&em=2',
            "img": m1101

        },
        {

            "title": "M1076 PLS trailer",
            "subtitle": "TRAILER, PLS 16 1/2 TON 8X20 M-1076",
            "url": 'https://onedrive.live.com/embed?resid=2387CE9E243192B9%2140580&authkey=!AG5cIt55zbaBFm4&em=2',
            "img": m1076
        },


    ];
    return (<>
        <Navigation/>
        <Container>
            <Row className="justify-content-md-center">

                {Cards.map(card => {

                    return <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                        <Card key={card.title}>
                            <Card.Img variant="top" style={{maxHeight: '130px', width: "auto", maxWidth: "90%"}}
                                      src={card.img}/>
                            <Card.Body>
                                <Card.Title>{card.title}</Card.Title>
                                <Card.Text>
                                    <span className={"subtitle"}>{card.subtitle}</span>
                                    <a href={card.url} target={"_blank"} rel="noreferrer">
                                        <img className="icon" src={icon} alt={card.title}/>
                                    </a>
                                </Card.Text>
                            </Card.Body>
                        </Card></Col>
                })}

            </Row>
        </Container>
    </>);
};


export default Home;




