import React from 'react';

function Footer(){
    return <div className="footer">
        <a href="mailto:Sgt Bhasin<manpreetbhasin@gmail.com>; Sgt Bhasin<manpreet.s.bhasin.mil@army.mil>?subject=Problem with 397enbn.com&body=Hello Sgt Bhasin, %0D%0A%0D%0A There is problem with 397enbn website described here: %0D%0A%0D%0A">Email me</a> if something is not working.
    </div>

}

export default Footer;
