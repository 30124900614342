import './App.css';
import React from 'react';
import Navigation from './components/Navigation';
import { Card, Col, Container, Row } from "react-bootstrap";

const Product = () => {
    const Cards = [{
        "topCategory": "Army Web Sites",
        "img": "./static/ako/outlook.png",
        "url": "https://webmail.apps.mil/mail/",
        "title": "Outlook"
    }, {
        "img": "./static/ako/qtc.jpg",
        "title": "QTC",
        "topCategory": "Army Web Sites",
        "url": "https://smp.qtcm.com/vdesk/hangup.php3"
    }, {
        "img": "./static/ako/medpros.png",
        "title": "Medpros",
        "topCategory": "Army Web Sites",
        "url": "https://medpros.mods.army.mil/MEDPROSNew/registerMedpros.aspx"
    },
    {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/armyignited.png",
        "url": "https://www.armyignited.army.mil/",
        "title": "Army Ignited"
    },
    {
        "topCategory": "DOD Resources",
        "title": "MYPAY",
        "url": "https://mypay.dfas.mil/",
        "img": "./static/ako/mypay.png"
    }, {
        "topCategory": "Professional Development",
        "title": "ARMY CAREER TRACKER",
        "url": "https://actnow.army.mil/",
        "img": "./static/ako/act.png"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/acool.png",
        "url": "https://www.cool.osd.mil/army/index.htm",
        "title": "Army Cool"
    }, {
        "topCategory": "Training",
        "title": "ARMY TRAINING INFORMATION SYSTEM",
        "url": "https://www.atis.army.mil/Index.html",
        "img": "./static/ako/atis.png"
    }, {
        "topCategory": "Training",
        "title": "DIGITAL TRAINING FACILITY",
        "url": "https://www.atis.army.mil/DTF.html",
        "img": "./static/ako/dtf.png"
    },


    {
        "topCategory": "DOD Resources",
        "title": "JOINT KNOWLEDGE ONLINE",
        "url": "https://jkodirect.jten.mil",
        "img": "./static/ako/jko.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "ARMY E-LEARNING",
        "url": "https://usarmy.skillport.com/skillportfe/custom/login/usarmy/login.action",
        "img": "./static/ako/elearning.png"
    }, {
        "topCategory": "Training",
        "title": "ARMY LEARNING MANAGEMENT SYSTEM",
        "url": "https://www.lms.army.mil/",
        "img": "./static/ako/alms.png"
    },

    {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/asl.png",
        "url": "https://www.army.mil/leaders/sa/",
        "title": "ARMY SENIOR LEADERSHIP"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/ap.png",
        "url": "https://armypubs.army.mil/",
        "title": "ARMY PUBLICATIONS"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/ippsa.png",
        "url": "https://ipps-a.army.mil/",
        "title": "IPPS-A"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/myerb.png",
        "url": "https://myerb.ahrs.army.mil/soldierLogin.do",
        "title": "MY ERB: ENLISTED RECORD BRIEF"
    }, {
        "topCategory": "Army Web Sites",
        "title": "MY ORB: OFFICER RECORD BRIEF",
        "url": "https://myorb.hrc.army.mil/",
        "img": "./static/ako/myorb.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "MY RFO: REQUEST FOR ORDERS",
        "url": "https://aim.hrc.army.mil/portal/usr_services.aspx",
        "img": "./static/ako/myrfo.jpeg"
    }, {
        "topCategory": "Army Web Sites",
        "title": "DAPMIS (DEPARTMENT OF THE ARMY PHOTO MANAGEMENT INFORMATION SYSTEM)",
        "url": "https://dapmis.hrc.army.mil/ImageAcceptForm.action",
        "img": "./static/ako/dapmis.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "ARMY IDEAS FOR INNOVATION (AI2)",
        "url": "https://www.milsuite.mil/book/community/spaces/ai2",
        "img": "./static/ako/aifi.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "SOLDIER ENHANCEMENT PROGRAM",
        "url": "https://www.peosoldier.army.mil/SEP/",
        "img": "./static/ako/soldierenhancementprogram.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "ANTI-TERRORISM ENTERPRISE PORTAL",
        "url": "https://army.deps.mil/army/sites/PMG/prog/ATEP/default.aspx",
        "img": "./static/ako/antiterrorism.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "DEPLOYMENT HEALTH ASSESSMENT PROGRAM",
        "url": "https://www.usar.army.mil/DHAP/",
        "img": "./static/ako/dhap.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "SUPPLY AND MAINTENANCE ASSISTANCE REVIEW TEAM (SMART)",
        "url": "https://www.milsuite.mil/book/docs/DOC-101393",
        "img": "./static/ako/supplymaintenance.png"
    },

    {
        "topCategory": "Army Web Sites",
        "title": "MY CLOTHING RECORD",
        "url": "https://ism.army.mil/ism/SelfServiceServlet?nav.nav_id=ssMyClothing",
        "img": "./static/ako/myclothing.png"
    },


    {
        "topCategory": "Army Web Sites",
        "title": "SEXUAL HARASSMENT/ASSAULT RESPONSE AND PREVENTION (SHARP)",
        "url": "https://www.sexualassault.army.mil/",
        "img": "./static/ako/sharp.png"
    }, {
        "topCategory": "Professional Development",
        "title": "ASSIGNMENT SATISFACTION KEY",
        "url": "https://www.ask.army.mil/ASK/ErrorPage.aspx",
        "img": "./static/ako/ask.png"
    }, {
        "topCategory": "Human Resources",
        "title": "ARMY BENEFITS CENTER",
        "url": "https://www.abc.army.mil/",
        "img": "./static/ako/abc.png "
    }, {
        "topCategory": "Army Web Sites",
        "title": "EVALUATION ENTRY SYSTEM",
        "url": "https://evaluations.hrc.army.mil/",
        "img": "./static/ako/ees.png"
    }, {
        "topCategory": "Army Web Sites",
        "title": "WEAPONS QUALIFICATION",
        "url": "",
        "img": "./static/ako/weaponsqual.png"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/milconnect.png",
        "url": "https://milconnect.dmdc.osd.mil/milconnect/",
        "title": "Milconnect"
    }, {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/amwr.png",
        "url": "https://www.armymwr.com/",
        "title": "Army Family and Morale, Welfare and Recreation"
    }, {
        "topCategory": "Human Resources",
        "title": "ARMY HUMAN RESOURCES COMMAND",
        "url": "https://www.hrc.army.mil/",
        "img": "./static/ako/ahrc.png"
    }, {
        "topCategory": "Health Resources",
        "title": "EBENEFITS",
        "url": "https://www.ebenefits.va.gov/ebenefits",
        "img": "./static/ako/ebenefits.png"
    }, {
        "topCategory": "Human Resources",
        "title": "IPERMS",
        "url": "https://iperms.hrc.army.mil/",
        "img": "./static/ako/iperms.jpeg"
    }, {
        "topCategory": "Human Resources",
        "title": "ARMY NATIONAL GUARD G1 PERSONNEL GATEWAY",
        "url": "https://arngg1.ngb.army.mil/Portal/Default.aspx",
        "img": "./static/ako/arngg1.png"
    },

    {
        "topCategory": "Professional Development",
        "title": "NCO LEADER CENTER FOR EXCELLENCE",
        "url": "https://www.ncolcoe.army.mil/",
        "img": "./static/ako/ncoleadercenter.png"
    }, {
        "topCategory": "Professional Development",
        "title": "CENTER FOR THE ARMY PROFESSION AND LEADERSHIP",
        "url": "https://capl.army.mil/",
        "img": "./static/ako/capl.png"
    },

    {
        "topCategory": "Family",
        "title": "SURVIVOR OUTREACH SERVICES",
        "url": "https://www.armymwr.com/programs-and-services/personal-assistance/survivor-outreach",
        "img": "./static/ako/sos.png"
    }, {
        "topCategory": "Family",
        "title": "COMPREHENSIVE SOLDIER AND FAMILY FITNESS",
        "url": "https://www.usar.army.mil/CSF/",
        "img": "./static/ako/csf.png"
    }, {
        "topCategory": "Health Resources",
        "title": "PSYCHOLOGICAL HEALTH CENTER OF EXCELLENCE",
        "url": "http://www.pdhealth.mil/",
        "img": "./static/ako/phcoe.png"
    }, {
        "topCategory": "Health Resources",
        "title": "TRICARE DENTAL READINESS",
        "url": "https://tricare.mil/CoveredServices/Dental/NGRDental/DentalReadiness",
        "img": "./static/ako/tdr.png"
    }, {
        "topCategory": "Finance",
        "title": "MILITARY ONESOURCE",
        "url": "https://www.militaryonesource.mil/",
        "img": "./static/ako/onesource.png"
    }, {
        "topCategory": "Health Resources",
        "title": "HEALTH.MIL",
        "url": "https://health.mil/About-MHS/OASDHA/Defense-Health-Agency/Education-and-Training/LEADS",
        "img": "./static/ako/healthmil.png"
    }, {
        "topCategory": "Health Resources",
        "title": "MY MEDPROS (VIEW MY RECORD)",
        "url": "https://medpros.mods.army.mil/portal/",
        "img": "./static/ako/mymedpros.png"
    }, {
        "topCategory": "Health Resources",
        "title": "ARMY SUBSTANCE ABUSE PROGRAM",
        "url": "https://sr2.army.mil/PROD_PUBLIC/",
        "img": "./static/ako/asap.png"
    }, {
        "topCategory": "Health Resources",
        "title": "AMEDD COMMAND MANAGEMENT SYSTEM",
        "url": "https://cms.mods.army.mil/cms/ ",
        "img": "./static/ako/amedd.png"
    }, {
        "topCategory": "Health Resources",
        "title": "ARMY PUBLIC HEALTH CENTER",
        "url": "https://phc.amedd.army.mil/Pages/default.aspx/",
        "img": "./static/ako/aphc.png"
    }, {
        "topCategory": "Health Resources",
        "title": "ARMY VETERINARY CORPS",
        "url": "https://veterinarycorps.amedd.army.mil/",
        "img": "./static/ako/armyvet.png"
    }, {
        "topCategory": "Resources/Information Technology",
        "title": "DOD CAC REFERENCE CENTER",
        "url": "https://www.cac.mil/",
        "img": "./static/ako/dodcac.png"
    }, {
        "topCategory": "Resources/Information Technology",
        "title": "DISA ANTI-VIRUS",
        "url": "https://disa.deps.mil/ext/cop/mae/CyberDefense/av-as/SitePages/Home.aspx",
        "img": "./static/ako/antivirus.png"
    }, {
        "topCategory": "Resources/Information Technology",
        "title": "DISA AV/AS HOME USE",
        "url": "https://storefront.disa.mil/kinetic/disa/service-catalog#/forms/antivirus-home-use",
        "img": "./static/ako/disa.png"
    }, {
        "topCategory": "DOD Resources",
        "title": "AIR FORCE WEATHER AGENCY",
        "url": "https://weather.af.mil/",
        "img": "./static/ako/afwa.png"
    }, {
        "topCategory": "DOD Resources",
        "title": "DS LOGIN REGISTRATION",
        "url": "https://myaccess.dmdc.osd.mil/identitymanagement/registration.do?execution=e1s1",
        "img": "./static/ako/dslogin.png"
    },

    {
        "topCategory": "DOD Resources",
        "title": "JOINT IMPROVISED-THREAT DEFEAT ORGANIZATION",
        "url": "https://www.dtra.mil/DTRA-Mission/JIDO/",
        "img": "./static/ako/dito.png"
    }, {
        "topCategory": "DOD Resources",
        "title": "DEFENSE TRAVEL SYSTEM",
        "url": "https://www.defensetravel.osd.mil/",
        "img": "./static/ako/dts.png"
    }, {
        "topCategory": "DOD Resources",
        "title": "DEFENSE TRAVEL MANAGEMENT SYSTEM",
        "url": "https://www.defensetravel.dod.mil/",
        "img": "./static/ako/dtms.png"
    },

    {
        "topCategory": "Training",
        "title": "ARMY TRAINING AND CERTIFICATION TRACKING SYSTEM",
        "url": "https://atc.us.army.mil/iastar/",
        "img": "./static/ako/atct.jpeg"
    }, {
        "topCategory": "Training",
        "img": "./static/ako/atn.png",
        "url": "https://atn.army.mil/",
        "title": "Army training Network"
    },

    {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/mtt.png",
        "url": "https://atiam.train.army.mil/mthp/",
        "title": "My Training Tab"
    },

    {
        "topCategory": "Army Web Sites",
        "img": "./static/ako/tag.png",
        "url": "https://public.tag.army.mil/catalog/tag/home",
        "title": "TRADOC Application Gateway"
    },


    {
        "topCategory": "Training",
        "title": "DEPLOYED DIGITAL TRAINING CAMPUS",
        "url": "https://www.atis.army.mil/DDTC.html",
        "img": "./static/ako/ddtc.png"
    }, {
        "topCategory": "Family",
        "title": "DEFENSE ENROLLMENT ELIGIBILITY REPORTING SYSTEM",
        "url": "https://www.tricare.mil/DEERS/",
        "img": "./static/ako/deer.png"
    }, {
        "topCategory": "Family",
        "title": "ARMY HOUSING ONLINE USER SERVICES",
        "url": "https://www.housing.army.mil/",
        "img": "./static/ako/ahous.png"
    }, {
        "topCategory": "Family",
        "title": "ARMY COMMUNITY SERVICE STAFF SYSTEM",
        "url": "https://www.acsstaff.org/skins/ACS/default_secure.aspx",
        "img": "./static/ako/acss.png"
    }, {
        "topCategory": "Family",
        "title": "ARMY FAMILY READINESS GROUP",
        "url": "https://www.armyfrg.org/skins/frg/home.aspx",
        "img": "./static/ako/armyfrg.png"
    }, {
        "topCategory": "Finance",
        "title": "DEFENSE FINANCE AND ACCOUNTING SERVICE",
        "url": "https://www.dfas.mil/",
        "img": "./static/ako/dfas.png"
    },

    {
        "topCategory": "Combat Readiness",
        "title": "ARMY COMBAT READINESS CENTER",
        "url": "https://safety.army.mil/",
        "img": "./static/ako/acrc.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "ARMY RISK ASSESSMENT PROGRAM",
        "url": "https://arap.safety.army.mil/",
        "img": "./static/ako/arap.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "GROUND RISK ASSESSMENT TOOL",
        "url": "https://jrat.safety.army.mil/login.aspx",
        "img": "./static/ako/grat.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "REPORT IT",
        "url": "https://reportit.safety.army.mil/",
        "img": "./static/ako/reportit.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "RISK MANAGEMENT INFORMATION SYSTEM",
        "url": "https://rmis.safety.army.mil/Login.aspx",
        "img": "./static/ako/rmis.png"
    }, {
        "topCategory": "Combat Readiness",
        "title": "TRAVEL RISK PLANNING SYSTEM",
        "url": "https://trips.safety.army.mil/",
        "img": "./static/ako/trips.png"
    },

    {
        "topCategory": "US Gov Web Sites",
        "title": "MEDLINEPLUS",
        "url": "https://medlineplus.gov/",
        "img": "./static/ako/medline.png"
    }, {
        "topCategory": "Finance",
        "title": "THRIFT SAVINGS PLAN",
        "url": "https://www.tsp.gov/index.html",
        "img": "./static/ako/tsp.png"
    }];

    // const NotAccessible = [
    //     {
    //     "topCategory": "Legal Resources",
    //     "title": "FINANCIAL DISCLOSURE MANAGEMENT",
    //     "url": "https://www.fdm.army.mil/",
    //     "img": "./static/ako/fdm.png"
    // }, {
    //     "topCategory": "Training",
    //     "title": "ARMY TRAINING REQUIREMENTS AND RESOURCES SYSTEM",
    //     "url": "https://www.atrrs.army.mil/",
    //     "img": "./static/ako/atrrs.png"
    // }
    //
    // ];
    //
    // const NotImportant = [
    //     {
    //     "topCategory": "Legal Resources",
    //     "title": "JAG UNIVERSITY",
    //     "url": "https://jagu.army.mil/webapps/portal/execute/tabs/tabAction?tab_tab_group_id=_22_1",
    //     "img": "./static/ako/jagu.png"
    // },
    // {
    //         "topCategory": "Center for Army Leadership",
    //         "url": "https://www.capl.army.mil/",
    //         "img": "https://www.capl.army.mil/temporary-image-storage/CAL%20Header.png",
    //         "title": "Center for Army Leadership",
    //         "style": {"background": "linear-gradient(45deg, #383838db, #383838)", "maxHeight": "190px"}
    //     },
    //
    // {
    //     "topCategory": "Army Web Sites",
    //     "title": "COMPUTER HARDWARE ENTERPRISE SOFTWARE AND SOLUTIONS (CHESS)",
    //     "url": "https://chess.army.mil/",
    //     "img": "./static/ako/chess.png"
    // }, {
    //     "topCategory": "Legal Resources",
    //     "title": "U.S. Armed Forces Legal Assistance Locator",
    //     "url": "https://legalassistance.law.af.mil/",
    //     "img": "./static/ako/usarmedlal.png"
    // }, {
    //     "topCategory": "Training",
    //     "title": "MILGAMING",
    //     "url": "https://milgaming.army.mil/",
    //     "img": "./static/ako/milgaming.png"
    // }, {
    //     "topCategory": "Legal Resources",
    //     "title": "JAG CORPS",
    //     "url": "https://www.goarmy.com/jag.html",
    //     "img": "./static/ako/jagcorps.png"
    // }, {
    //     "topCategory": "US Gov Web Sites",
    //     "title": "EAGLECASH",
    //     "disabled": true,
    //     "url": "https://www.fiscal.treasury.gov/eaglecash/",
    //     "img": "./static/ako/eaglecash.png"
    // }, {
    //     "topCategory": "Training",
    //     "title": "MOBILE DIGITAL TRAINING FACILITY",
    //     "url": "https://www.atis.army.mil/MDTF.html",
    //     "img": "./static/ako/mdtf.png"
    // }, {
    //     "topCategory": "Legal Resources",
    //     "title": "TRIAL DEFENSE SERVICE",
    //     "url": "https://www.mdwhome.mdw.army.mil/sja_nav/trial-defense/",
    //     "img": "./static/ako/tds.png"
    // }, {
    //     "topCategory": "Health Resources",
    //     "title": "G1 SUICIDE PREVENTION",
    //     "url": "https://www.armyg1.army.mil/hr/suicide/default.asp",
    //     "img": "./static/ako/g1suicide.png"
    // }];

    return (<>
        <Navigation />
        <Container>
            <Row className="justify-content-md-center">
                {Cards.map(card => {
                    return <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                        <Card onClick={() => window.open(card.url)}>
                            <Card.Img variant="top" src={card.img} style={card.style} />
                            <Card.Body>
                                <Card.Title>{card.title}</Card.Title>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                        </Card></Col>
                })}
            </Row>
        </Container>
    </>);
};
export default Product;
