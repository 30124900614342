import './App.css';
import Product from "./product";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
function App() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/links" element={<Product />} />
            </Routes>
        </BrowserRouter>
        <Footer />
    </>);
}

export default App;
